import { axios as client } from './apiAdmin';
import { exportFile } from '../requestDownloadFile';
export default {
  // 获取商家近 30 天的广告活动聚合报表
  getSellerReport: data => {
    return client.get('seller/report/campaign-agg-30d', { params: data }).then(res => res.data);
  },
  getSellerList: data => {
    return client.get('seller/list', { params: data }).then(res => res.data);
  },
  sellerExport: data => {//导出seller
    return exportFile(`seller/export`, data.qs, data.fileName, data.platform);
  },
  sellerReportExport: data => {//导出seller report
    return exportFile(`seller/report/campaign-agg-30d/export`, data.qs, data.fileName, data.platform);
  },
  // 获取商家账单
  getSellerPayments: data => {
    return client.get('seller/billings', { params: data }).then(res => res.data);
  },
  // 获取商家详细信息
  getSellerDetails: data => {
    return client.get('seller/details', { params: data }).then(res => res.data);
  }
}